import {useMemo, useState} from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchEvaluationThumbsThunk } from "../../../store/thunk/reportingThunk";
import { useEvaluationThumbsData } from "../../../store/selectors/reportingSelectors";
import { EvaluationStarsDataWrapper, NoDataContainer, NoDataText, NoFeedbackContainer, NoFeedbackText, OptionContainer, RequestButton } from "../Dashboard.styles";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { chartColorList } from "../../actionModule/constants";
import {FeedbackData} from "../../../types/reportingTypes";
import BorderRow from "../../../components/common/table/BorderRow";
import {BasicTableRowStyles, TableWrapper} from "../../../components/common/Common.styles";
import {TableColumnHeaderWrapper} from "../../../components/common/table/ServerSideTableColumnHeader.styles";
import CellBox from "../../../components/common/table/CellBox";

export const EvaluationThumbsChart = ({ actionModuleId }: { actionModuleId: number }) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [hasApplied, setHasApplied] = useState(false);
    const evaluationThumbsData = useEvaluationThumbsData() || [];
    const dispatch = useAppDispatch();

    const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
        let formattedDate = newDate?.format('YYYY-MM-DD')
        formattedDate && setStartDate(formattedDate)
    }
    const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
        let formattedDate = newDate?.format('YYYY-MM-DD')
        formattedDate && setEndDate(formattedDate)
    }
    const confirmDisplayOptions = async () => {
        if (!startDate || !endDate) return;
        setHasApplied(true);
        await dispatch(fetchEvaluationThumbsThunk({ actionModuleId, startDate, endDate }));
    }
    const evaluationData = evaluationThumbsData.map((data) => ({
        endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
        positive: data.positiveAnswered,
        negative: data.negativeAnswered,
        unanswered: data.notAnswered,
    }));
    const feedbackList = evaluationThumbsData.flatMap((data) => data.feedback);

    const isDataValid = useMemo(() => {
      return (
        evaluationThumbsData.length > 0 &&
        evaluationThumbsData.every(
          (data) =>
            !isNaN(data.positiveAnswered) &&
            !isNaN(data.negativeAnswered) &&
            !isNaN(data.notAnswered)
        )
      );
    }, [evaluationThumbsData]);

    const yFormatter = (v) => dayjs(v).format("DD.MM.YYYY");

    return (
        <div>
            <OptionContainer>
                <DatePicker
                    onChange={handleStartDateChange}
                    format="DD.MM.YYYY"
                />
                <DatePicker
                    onChange={handleEndDateChange}
                    format="DD.MM.YYYY"
                />
                <RequestButton
                    onClick={() => confirmDisplayOptions()}
                    disabled={!startDate || !endDate}>
                    {t("buttons.apply")}
                </RequestButton>
            </OptionContainer>

            {hasApplied && !isDataValid && (
                <NoDataContainer>
                    <NoDataText>{t("dashboardPage.evaluationThumbs.noDisplayableData")}</NoDataText>
                </NoDataContainer>
            )}

            {hasApplied && isDataValid && (
                <EvaluationStarsDataWrapper>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={evaluationData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="endDate" tickFormatter={yFormatter} />
                            <YAxis domain={[0, 'dataMax']}/>
                            <Tooltip labelFormatter={yFormatter} />
                            <Legend />
                            <Bar dataKey="positive" name={t("dashboardPage.evaluationThumbs.positive")} stackId="a" fill={chartColorList[0]} barSize={20} />
                            <Bar dataKey="negative" name={t("dashboardPage.evaluationThumbs.negative")} stackId="a" fill={chartColorList[1]} barSize={20} />
                            <Bar dataKey="unanswered" name={t("dashboardPage.evaluationThumbs.notAnswered")} stackId="a" fill={chartColorList[2]} barSize={20} />
                        </BarChart>
                    </ResponsiveContainer>

                    <Box sx={{ marginTop: 5 }} width="100%">
                        {feedbackList.length > 0
                            ? <TextFeedbackTable feedback={feedbackList}/>
                            : <NoFeedbackContainer>
                                <NoFeedbackText variant="h6" sx={{ color: 'gray' }}>
                                    {t("dashboardPage.evaluationThumbs.noFeedback")}
                                </NoFeedbackText>
                            </NoFeedbackContainer>
                        }
                    </Box>
                </EvaluationStarsDataWrapper>
            )}
        </div>
    )
}

interface TextFeedbackTableProps {
  feedback: FeedbackData[]
}

function TextFeedbackTable(props: TextFeedbackTableProps) {
  return <>
    <TableWrapper
      columnsTemplate="1fr 1fr 5fr"
      width="100%"
    >
      <TableColumnHeaderWrapper>
        <Typography variant="subtitle1">Name</Typography>
      </TableColumnHeaderWrapper>
      <TableColumnHeaderWrapper>
        <Typography variant="subtitle1">Datum</Typography>
      </TableColumnHeaderWrapper>
      <TableColumnHeaderWrapper>
        <Typography variant="subtitle1">Feedback</Typography>
      </TableColumnHeaderWrapper>

      <BorderRow />

      <Box sx={{ display: "contents" }}>
        {
          props.feedback.map((f) => <BasicTableRowStyles>
            <CellBox>
              <Typography>{f.name}</Typography>
            </CellBox>
            <CellBox>
              <Typography>{dayjs(f.date).format("DD.MM.YYYY")}</Typography>
            </CellBox>
            <CellBox>
              <Typography>{f.text}</Typography>
            </CellBox>
            <BorderRow />
          </BasicTableRowStyles>)
        }
      </Box>
    </TableWrapper>
  </>
}