import { ExcelLink, FileHandlingWrapper, TemplateDownload } from '../../../ActionsBlock.styles'
import GridOnIcon from '@mui/icons-material/GridOn';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FileHandling: React.FC<{ subtypeName: string, actionModulesAmount?: number }> = ({ subtypeName, actionModulesAmount }) => {
  const { t } = useTranslation();

  const localizedSubtype = t(`incentivationPage.${subtypeName}`)

  return (
    <FileHandlingWrapper>
      {
        actionModulesAmount === 1 ?
          <Typography variant='h5'>{t("incentivationPage.excelUploadOneModule")}</Typography> :
          <Typography variant='h5'>{t("incentivationPage.excelUpload")} ({localizedSubtype})</Typography>
      }
      <TemplateDownload>
        <GridOnIcon sx={{ fontSize: "2rem", paddingRight: "0.25em" }} />
        <Typography>
          <ExcelLink href="/files/templates/EmployeeNumber_Score.xlsx">{t("incentivationPage.templateDownload")}</ExcelLink>
        </Typography>
      </TemplateDownload>
    </FileHandlingWrapper>
  )
}

export default FileHandling