import { SvgIcon, Tooltip } from "@mui/material";
import { StatusTypes } from "../../types/common";
import { theme } from "../../theme";

export const ActionIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={color}>
                <g>
                    <rect fill="none" height="24" width="24" />
                </g>
                <g>
                    <g>
                        <polygon points="19.8,2 11.6,8.7 10.39,7.66 13.99,5.58 9.41,1 8,2.41 10.74,5.15 5,8.46 3.81,12.75 6.27,17 8,16 5.97,12.48 6.32,11.18 9.5,13 10,22 12,22 12.5,12 21,3.4" />
                        <circle cx="5" cy="5" r="2" />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
};

export const AnalyticsIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <g data-name="Icon ionic-md-analytics">
                    <path
                        data-name="Path 1"
                        d="m16.2 12.309-3.5 5.771a2.154 2.154 0 0 1 .434 1.311 2.007 2.007 0 0 1-1.928 2.076 2.007 2.007 0 0 1-1.926-2.076 2.446 2.446 0 0 1 .032-.385L7 17.557a1.824 1.824 0 0 1-2.286.311L2.25 20.351v2.474a1.789 1.789 0 0 0 1.715 1.847h14.57a1.789 1.789 0 0 0 1.715-1.847v-9.2l-2.35-1.679a1.833 1.833 0 0 1-1.181.437 1.8 1.8 0 0 1-.519-.074z"
                        transform="translate(-2.25 -6.673)"
                        fill={color ? color : "#404040"}
                    />
                    <path
                        data-name="Path 2"
                        d="M5.664 10.686a2.007 2.007 0 0 1 1.928 2.076c0 .091-.008.182-.016.268l2.414 1.453a1.829 1.829 0 0 1 1.221-.467 1.879 1.879 0 0 1 .418.048l3.511-5.857A2.164 2.164 0 0 1 14.783 7a1.933 1.933 0 1 1 3.856 0 2.423 2.423 0 0 1-.052.489l1.659 1.194V5.222a1.789 1.789 0 0 0-1.716-1.847H3.965A1.789 1.789 0 0 0 2.25 5.222v9.919l1.607-1.657a2.242 2.242 0 0 1-.121-.722 2.007 2.007 0 0 1 1.928-2.076z"
                        fill={color ? color : "#404040"}
                        transform="translate(-2.25 -3.375)"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const BlockIcon = ({ color }: { color?: string }) => {
    return (
        <Tooltip title={StatusTypes.Blocked}>
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                    <path d="M480-34q-92.64 0-173.98-35.02-81.34-35.02-141.66-95.84-60.32-60.82-95.34-142.66T34-482q0-91.64 35.02-172.98 35.02-81.34 95.34-141.66 60.32-60.32 142.16-94.84T480-926q91.64 0 173.48 34.52 81.84 34.52 142.16 94.84 60.32 60.32 95.34 141.66T926-482q0 92.64-35.02 174.48-35.02 81.84-95.34 142.66-60.32 60.82-141.66 95.84T480-34Zm0-136q45 0 88-12t80-38L218-649q-24 38-36 80.5T170-482q0 130 90.5 221T480-170Zm263-145q23-38 35-80.5t12-86.5q0-128-90.5-218T480-790q-44 0-86 11t-80 34l429 430Z" />
                </svg>
            </SvgIcon>
        </Tooltip>
    );
};

export const CheckIcon = ({ color }: { color?: string }) => {
    return (
        <Tooltip title={StatusTypes.Active}>
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                    <path d="m422.3-271.48 304.57-305.13-82.48-83.61L422.3-438.13 313.65-546.78l-83.04 83.61L422.3-271.48ZM480-40.09q-91.34 0-171.86-34.35-80.53-34.35-139.94-93.76-59.41-59.41-93.76-139.94Q40.09-388.66 40.09-480q0-91.59 34.41-172.06 34.4-80.47 93.92-140.05 59.51-59.58 139.93-93.69 80.43-34.11 171.65-34.11 91.58 0 172.04 34.11 80.47 34.11 140.06 93.7t93.7 140.08q34.11 80.49 34.11 172.1 0 91.62-34.11 171.8-34.11 80.19-93.69 139.7-59.58 59.52-140.05 93.92Q571.59-40.09 480-40.09Z" />
                </svg>
            </SvgIcon>
        </Tooltip>
    );
};

export const ChevronDownIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.341"
                height="9.317"
                viewBox="0 0 14.341 9.317"
            >
                <path
                    data-name="Icon awesome-chevron-down"
                    d="M7.011 17.753.629 10.92a.886.886 0 0 1 0-1.193l.744-.8a.752.752 0 0 1 1.113 0l5.082 5.415 5.082-5.413a.752.752 0 0 1 1.113 0l.744.8a.886.886 0 0 1 0 1.193l-6.381 6.831a.752.752 0 0 1-1.115 0z"
                    transform="translate(-.398 -8.683)"
                    fill={color ? color : "#404040"}
                />
            </svg>
        </SvgIcon>
    );
};

export const CirclePlusIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="58.335"
                height="58.335"
                viewBox="0 0 58.335 58.335"
            >
                <g data-name="Icon feather-plus-circle">
                    <path
                        data-name="Path 30"
                        d="M57.335 30.167A27.167 27.167 0 1 1 30.167 3a27.167 27.167 0 0 1 27.168 27.167z"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                        transform="translate(-1 -1)"
                    />
                    <path
                        data-name="Path 31"
                        d="M18 12v21.734"
                        transform="translate(11.167 6.3)"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                    />
                    <path
                        data-name="Path 32"
                        d="M12 18h21.734"
                        transform="translate(6.3 11.167)"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const ClockIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.694"
                height="18.694"
                viewBox="0 0 18.694 18.694"
            >
                <g data-name="Icon ionic-md-time">
                    <path
                        data-name="Path 8"
                        d="M12.713 3.375a9.347 9.347 0 1 0 9.356 9.347 9.344 9.344 0 0 0-9.356-9.347zm.009 16.825a7.478 7.478 0 1 1 7.478-7.478 7.478 7.478 0 0 1-7.478 7.478z"
                        style={{ fill: "#404040" }}
                        transform="translate(-3.375 -3.375)"
                    />
                    <path
                        data-name="Path 9"
                        d="M17.94 10.688h-1.4V16.3l4.907 2.943.7-1.15-4.207-2.498z"
                        transform="translate(-8.125 -6.014)"
                        style={{ fill: "#404040" }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const CoinsIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <path
                    data-name="Icon awesome-coins"
                    d="M0 14.249v1.5C0 16.991 3.023 18 6.75 18s6.75-1.009 6.75-2.25v-1.5c-1.452 1.023-4.106 1.5-6.75 1.5S1.452 15.272 0 14.249zM11.25 4.5C14.977 4.5 18 3.491 18 2.25S14.977 0 11.25 0 4.5 1.009 4.5 2.25 7.523 4.5 11.25 4.5zM0 10.561v1.814c0 1.241 3.023 2.25 6.75 2.25s6.75-1.009 6.75-2.25v-1.814c-1.452 1.2-4.11 1.814-6.75 1.814S1.452 11.756 0 10.561zm14.625.387C16.639 10.557 18 9.833 18 9V7.5a8.633 8.633 0 0 1-3.375 1.213zM6.75 5.625C3.023 5.625 0 6.884 0 8.438s3.023 2.812 6.75 2.812 6.75-1.259 6.75-2.812-3.023-2.813-6.75-2.813zM14.46 7.6C16.569 7.225 18 6.479 18 5.625v-1.5a11.445 11.445 0 0 1-5.65 1.47A3.937 3.937 0 0 1 14.46 7.6z"
                    style={{ fill: color ? color : "#404040" }}
                />
            </svg>
        </SvgIcon>
    );
};

export const DocumentIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                <path d="M120-120q-33 0-56.5-23.5T40-200v-520h80v520h680v80H120Zm160-160q-33 0-56.5-23.5T200-360v-440q0-33 23.5-56.5T280-880h200l80 80h280q33 0 56.5 23.5T920-720v360q0 33-23.5 56.5T840-280H280Zm0-80h560v-360H527l-80-80H280v440Zm0 0v-440 440Z" />
            </svg>
        </SvgIcon>
    );
};

export const SignatureIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
            </svg>
        </SvgIcon>
    );
};

export const FamilyStarIcon = ({ color = "white" }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                <path d="M480-320q48 0 85.5-28.5T620-422H340q17 45 54.5 73.5T480-320ZM380-480q25 0 42.5-17.5T440-540q0-25-17.5-42.5T380-600q-25 0-42.5 17.5T320-540q0 25 17.5 42.5T380-480Zm200 0q25 0 42.5-17.5T640-540q0-25-17.5-42.5T580-600q-25 0-42.5 17.5T520-540q0 25 17.5 42.5T580-480ZM305-704l112-145q12-16 28.5-23.5T480-880q18 0 34.5 7.5T543-849l112 145 170 57q26 8 41 29.5t15 47.5q0 12-3.5 24T866-523L756-367l4 164q1 35-23 59t-56 24q-2 0-22-3l-179-50-179 50q-5 2-11 2.5t-11 .5q-32 0-56-24t-23-59l4-165L95-523q-8-11-11.5-23T80-570q0-25 14.5-46.5T135-647l170-57Zm49 69-194 64 124 179-4 191 200-55 200 56-4-192 124-177-194-66-126-165-126 165Zm126 135Z" />
            </svg>
        </SvgIcon>
    );
};

export const DoneIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
            >
                <path
                    fill={color ? color : "#404040"}
                    d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"
                />
            </svg>
        </SvgIcon>
    );
};

export const EditIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.963"
                height="25.963"
                viewBox="0 0 25.963 25.963"
            >
                <g data-name="Icon feather-edit">
                    <path
                        fill={theme.palette.companyMain.main}
                        data-name="Path 10"
                        d="M13.718 6H5.382A2.382 2.382 0 0 0 3 8.382v16.673a2.382 2.382 0 0 0 2.382 2.382h16.673a2.382 2.382 0 0 0 2.382-2.382v-8.337"
                        transform="translate(-2 -2.474)"
                        style={{
                            fill: "none",
                            stroke: theme.palette.companyMain.main,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                    <path
                        data-name="Path 11"
                        d="M24.5 3.558a2.526 2.526 0 0 1 3.573 3.573L16.764 18.444 12 19.635l1.191-4.764z"
                        transform="translate(-3.855 -1.818)"
                        style={{
                            fill: "none",
                            stroke: theme.palette.companyMain.main,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const GroupViewIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19.5"
                viewBox="0 0 18 19.5"
            >
                <path
                    data-name="Icon material-view-module"
                    d="M6 16.5h5.294v-9H6zM6 27h5.294v-9H6zm6.353 0h5.294v-9h-5.294zm6.353 0H24v-9h-5.294zm-6.353-10.5h5.294v-9h-5.294zm6.353-9v9H24v-9z"
                    transform="translate(-6 -7.5)"
                    fill="#fff"
                />
            </svg>
        </SvgIcon>
    );
};

export const HomeIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.571"
                height="18"
                viewBox="0 0 20.571 18"
            >
                <path
                    data-name="Icon open-home"
                    d="M10.286 0 0 7.714h2.571V18h5.143v-5.143h5.143V18H18V7.637l2.571.077z"
                    style={{ fill: " #fff " }}
                />
            </svg>
        </SvgIcon>
    );
};

export const MoreIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="27"
                viewBox="0 0 6 27"
            >
                <g data-name="Icon feather-more-vertical">
                    <path
                        data-name="Path 5"
                        d="M19.5 18a1.5 1.5 0 1 1-1.5-1.5 1.5 1.5 0 0 1 1.5 1.5z"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "3px",
                        }}
                        transform="translate(-15 -4.5)"
                    />
                    <path
                        data-name="Path 6"
                        d="M19.5 7.5A1.5 1.5 0 1 1 18 6a1.5 1.5 0 0 1 1.5 1.5z"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "3px",
                        }}
                        transform="translate(-15 -4.5)"
                    />
                    <path
                        data-name="Path 7"
                        d="M19.5 28.5A1.5 1.5 0 1 1 18 27a1.5 1.5 0 0 1 1.5 1.5z"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "3px",
                        }}
                        transform="translate(-15 -4.5)"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const ReceiptLongIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#48752C"
            >
                <path
                    d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z"
                />
            </svg>
        </SvgIcon>
    );
};

export const RepeatIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.727"
                height="18.689"
                viewBox="0 0 16.727 18.689"
            >
                <g data-name="Icon feather-repeat">
                    <path
                        data-name="Path 12"
                        d="m25.5 1.5 3.273 3.273L25.5 8.045"
                        transform="translate(-13.045 -.086)"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                    <path
                        data-name="Path 13"
                        d="M4.5 12.409v-1.636A3.273 3.273 0 0 1 7.773 7.5h11.454"
                        transform="translate(-3.5 -3.348)"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                    <path
                        data-name="Path 14"
                        d="M7.773 29.045 4.5 25.773 7.773 22.5"
                        transform="translate(-3.5 -11.771)"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                    <path
                        data-name="Path 15"
                        d="M19.227 19.5v1.636a3.273 3.273 0 0 1-3.273 3.273H4.5"
                        transform="translate(-3.5 -9.872)"
                        style={{
                            fill: "none",
                            stroke: "#404040",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const GroupIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#000000">
                <path
                    d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
            </svg>
        </SvgIcon>
    );
};

export const SimpleSearchIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.731"
                height="20.731"
                viewBox="0 0 20.731 20.731"
            >
                <g data-name="Icon feather-search">
                    <path
                        data-name="Path 3"
                        d="M19.709 12.1a7.6 7.6 0 1 1-7.6-7.6 7.6 7.6 0 0 1 7.6 7.6z"
                        style={{
                            fill: "none",
                            stroke: color,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "3px",
                        }}
                        transform="translate(-3 -3)"
                    />
                    <path
                        data-name="Path 4"
                        d="m29.11 29.11-4.135-4.135"
                        transform="translate(-10.5 -10.5)"
                        style={{
                            fill: "none",
                            stroke: color,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "3px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const SortIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10.059"
                height="13.996"
                viewBox="0 0 10.059 13.996"
            >
                <path
                    data-name="Icon awesome-sort"
                    d="M2.037 12.127H10.4a.845.845 0 0 1 .6 1.441l-4.182 4.184a.84.84 0 0 1-1.192 0l-4.187-4.183a.845.845 0 0 1 .598-1.442zM11 8.436 6.818 4.252a.84.84 0 0 0-1.192 0L1.439 8.436a.845.845 0 0 0 .6 1.441H10.4a.845.845 0 0 0 .6-1.441z"
                    transform="translate(-1.191 -4.004)"
                    style={{ fill: "#404040" }}
                />
            </svg>
        </SvgIcon>
    );
};

export const SettingsIcon = () => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
                <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
            </svg>
        </SvgIcon>
    );
};

export const SuperAdminIcon = ({ color }: { color?: string }) => {
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill={color}>
                <path d="M480-40q-186 0-313-69.5T40-280q0-69 64-126.5T280-494v82q-73 23-116.5 59T120-280q0 64 108 112t252 48q144 0 252-48t108-112q0-37-43.5-73T680-412v-82q112 30 176 87.5T920-280q0 101-127 170.5T480-40ZM360-200v-440H160v-80h640v80H600v440h-80v-200h-80v200h-80Zm120-560q-33 0-56.5-23.5T400-840q0-33 23.5-56.5T480-920q33 0 56.5 23.5T560-840q0 33-23.5 56.5T480-760Z" />
            </svg>
        </SvgIcon>
    );
};
