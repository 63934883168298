import {Box, Button, Typography} from "@mui/material";
import {BasicTableRowStyles, TableHeaderWrapper} from "../../../components/common/Common.styles";
import {useTranslation} from "react-i18next";
import CellBox from "../../../components/common/table/CellBox";
import {useEffect, useState} from "react";
import {TableColumnHeaderWrapper} from "../../../components/common/table/ServerSideTableColumnHeader.styles";
import {ExpandedTableStyle, ModalImageContainer} from "../ActionModuleManagement.styles";
import {ModalComponent} from "../../../components/common/ModalComponent";
import {useAppDispatch} from "../../../hooks/StoreHooks";
import {actionsApi} from "../../../api/actionsApi";
import {setProcessingRequest} from "../../../store/reducers/tableManagementReducer";
import {ActionItem, ActionModuleInstancesEditItem, FulfilledStatusType} from "../../../types/actionItem";
import FulfilledStatusIcon, {FulfilledIconWrapper} from "../../../components/common/table/FulfilledStatusIcon";
import {useActionEditMode} from "../../../store/selectors/actionsSelectors";
import {setEditedActionInstancesData} from "../../../store/reducers/actionsReducer";
import ActionModuleInstancesType from "../../../types/actionModuleInstancesType";
import EmployeeIncentivationType from "../../../types/employeeIncentivationType";

interface Props {
  rowData: EmployeeIncentivationType;
  showFulfillment: boolean;
  showPartiallyState: boolean;
  showAnswer: boolean;
  showScore: boolean;
  showSuccessfulUploads: boolean;
  actionItem: ActionItem;
}

const ExpandedTable = (props: Props) => {
  const dispatch = useAppDispatch();
  const [tableContent, setTableContent] = useState<ActionModuleInstancesType[]>([])

  useEffect(() => {
    const fetchInstances = async () => {
      setTableContent([]);
      dispatch(setProcessingRequest(true));
      const res = await actionsApi.getActionModuleInstances(
        props.rowData.actionModuleId,
        props.rowData.id
      );
      setTableContent(res.result ?? []);
      dispatch(setProcessingRequest(false));
    };
    fetchInstances()
  }, [props.rowData.id, props.rowData.actionModuleId, dispatch]);

  const tableTemplateColumns: string = `3fr ${props.showSuccessfulUploads ? "2fr" : ""} ${props.showFulfillment ? `2fr ${props.showPartiallyState ? "2fr" : ""} 2fr` : ""} ${props.showScore ? "2fr" : ""} ${props.showAnswer ? "2fr" : ""}`;

  return <ExpandedTableStyle>
    {tableContent[0] &&
        <Box gridTemplateColumns={tableTemplateColumns} display="grid">
            <ExpandedTableHeader
                showFulfillment={props.showFulfillment}
                showPartiallyState={props.showPartiallyState}
                showAnswer={props.showAnswer}
                showScore={props.showScore}
                showSuccessfulUploads={props.showSuccessfulUploads}
            />
            <Box sx={{display: "contents"}}>
              {
                tableContent.map((u, i) =>
                  <ExpandedTableRow
                    key={i}
                    row={u}
                    employeeId={props.rowData.id}
                    showFulfillment={props.showFulfillment}
                    showPartiallyState={props.showPartiallyState}
                    showAnswer={props.showAnswer}
                    showScore={props.showScore}
                    showSuccessfulUploads={props.showSuccessfulUploads}
                    actionItem={props.actionItem}
                    actionModuleId={props.rowData.actionModuleId}/>)
              }
            </Box>
        </Box>
    }
  </ExpandedTableStyle>
}

interface ExpandedTableHeaderProps {
  showFulfillment: boolean;
  showPartiallyState: boolean;
  showAnswer: boolean;
  showScore: boolean;
  showSuccessfulUploads: boolean;
}

function ExpandedTableHeader(props: ExpandedTableHeaderProps) {
  const {t} = useTranslation();

  return <TableHeaderWrapper>
    <TableColumnHeaderWrapper style={{
      ...({paddingLeft: "25px"}),
      ...({paddingRight: "25px"}),
    }}>
      <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}} display={"inline-block"}>
        {t("tableColumnHeaders.lastChange")}
      </Typography>
    </TableColumnHeaderWrapper>
    {
      props.showSuccessfulUploads &&
        <TableColumnHeaderWrapper>
            <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}} display={"inline-block"}>
              {t("tableColumnHeaders.successfulUploads")}
            </Typography>
        </TableColumnHeaderWrapper>
    }
    {
      props.showFulfillment &&
        <>
            <TableColumnHeaderWrapper>
                <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                            display={"inline-block"}>
                  {t("tableColumnHeaders.notFulfilled")}
                </Typography>
            </TableColumnHeaderWrapper>
          {
            props.showPartiallyState &&
              <TableColumnHeaderWrapper>
                  <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                              display={"inline-block"}>
                    {t("tableColumnHeaders.partiallyFulfilled")}
                  </Typography>
              </TableColumnHeaderWrapper>
          }
            <TableColumnHeaderWrapper>
                <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                            display={"inline-block"}>
                  {t("tableColumnHeaders.fulfilled")}
                </Typography>
            </TableColumnHeaderWrapper>
        </>
    }
    {
      props.showScore &&
        <TableColumnHeaderWrapper>
            <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                        display={"inline-block"}>
              {t("tableColumnHeaders.scoreValue")}
            </Typography>
        </TableColumnHeaderWrapper>
    }
    {
      props.showAnswer &&
        <TableColumnHeaderWrapper>
            <Typography style={{flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                        display={"inline-block"}>
              {t("tableColumnHeaders.answer")}
            </Typography>
        </TableColumnHeaderWrapper>
    }
  </TableHeaderWrapper>;
}

interface ActionModuleInstancesTypeRowProps {
  row: ActionModuleInstancesType;
  employeeId: string;
  actionModuleId: string;
  showFulfillment: boolean;
  showPartiallyState: boolean;
  showAnswer: boolean;
  showScore: boolean;
  showSuccessfulUploads: boolean;
  actionItem: ActionItem;
}

function ExpandedTableRow(props: ActionModuleInstancesTypeRowProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const [fulfilledState, setFulfilledState] = useState<boolean>(props.row.fulfillmentStatus === FulfilledStatusType.Fulfilled)
  const actionEditMode = useActionEditMode();
  const closeModal = () => {
    setSuccessModal(false)
  }

  function handleFulfillmentStatusChange(newState: FulfilledStatusType) {
    setFulfilledState(newState === FulfilledStatusType.Fulfilled)
    let newInstance: ActionModuleInstancesEditItem = {
      actionModuleId: props.actionModuleId,
      employeeId: props.employeeId,
      actionModuleInstanceId: props.row.actionModuleInstanceId,
      fulfilledStatus: newState === FulfilledStatusType.Fulfilled ? FulfilledStatusType.Fulfilled : FulfilledStatusType.NotFulfilled
    }
    dispatch(setEditedActionInstancesData(newInstance))
  }

  return <>
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <Typography>{props.row.completedAt?.substring(0, 10)}</Typography>
      </CellBox>
      {
        props.showSuccessfulUploads &&
          <CellBox>
              <>
                {(props.row.attachmentFiles && props.row.attachmentFiles.length > 0) &&
                  props.row.attachmentFiles.map((fileUrl, i) => (
                    <Box key={fileUrl}
                         onClick={() => setSuccessModal(true)}
                         sx={{'&:hover': {cursor: 'pointer'}}}>
                      File {i + 1} / {props.row.attachmentFiles!.length}
                    </Box>
                  ))
                }
              </>
          </CellBox>
      }
      {
        props.showFulfillment &&
          <>
              <CellBox>
                  <Button
                      disabled={!actionEditMode}
                      onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.NotFulfilled)}>
                    {!fulfilledState
                      ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.NotFulfilled}/>
                      : <FulfilledIconWrapper/>
                    }
                  </Button>
              </CellBox>
            {
              props.showPartiallyState &&
                <CellBox>
                    <Button
                        disabled={!actionEditMode}
                        onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.NotFulfilled)}>
                      {!fulfilledState
                        ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.NotFulfilled}/>
                        : <FulfilledIconWrapper/>
                      }
                    </Button>
                </CellBox>
            }
              <CellBox>
                  <Button
                      disabled={!actionEditMode}
                      onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Fulfilled)}>
                    {fulfilledState
                      ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Fulfilled}/>
                      : <FulfilledIconWrapper/>
                    }
                  </Button>
              </CellBox>
          </>
      }
      {
        props.showScore &&
          <CellBox>
              <Typography>{props.row.score ?? "-"}</Typography>
          </CellBox>
      }
      {
        props.showAnswer &&
          <CellBox>
              <>
                {!props.row.answer &&
                    <Typography>{t("tableColumnHeaders.notAnswered")}</Typography>}
                {props.row.answer === "Positive" &&
                    <Typography>{props.actionItem.positiveAnswerText ?? ""}</Typography>}
                {props.row.answer === "Negative" &&
                    <Typography>{props.actionItem.negativeAnswerText ?? ""}</Typography>}
              </>
          </CellBox>
      }
    </BasicTableRowStyles>

    {
      props.row.attachmentFiles &&
        <ModalComponent isOpen={successModal} onClose={closeModal}>
            <ModalImageContainer>
                <img
                    key={Date.now()}
                    src={props.row.attachmentFiles[0]}
                    height="100%"
                    width="90%"
                    alt="workspacee"
                    style={{objectFit: "contain", maxWidth: "90%", maxHeight: "90%"}}/>
            </ModalImageContainer>
        </ModalComponent>
    }
  </>
}

export default ExpandedTable