import AccountManagementTableHeader from "./AccountManagementTableHeader";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../../components/common/Common.styles";
import { Box } from "@mui/material";
import { ServerSidePagination } from "../../../components/common/table/ServerSidePagination";
import AccountManagementCreateEditUser from "./AccountManagementCreateEditUser";
import {
  useAccountManagementEditMode,
  useAccountManagementNewUserMode
} from "../../../store/selectors/accountManagementSelectors";
import AccountManagementRow from "./AccountManagementRow";
import { AccountManagementUserType } from "../../../types/accountManagementUsersType";
import { GroupItem } from "../../../types/groupItem";
import { PaginatedData } from "../../../hooks/PaginationHooks";
import { useActionsData } from "../../../store/selectors/actionsSelectors";
import { useEffect } from "react";
import { fetchActions } from "../../../store/thunk/actionsThunk";
import { useAppDispatch } from "../../../hooks/StoreHooks";

interface AccountManagementTableProps {
  data: PaginatedData<AccountManagementUserType>,
  groupList: GroupItem[];
}

function AccountManagementTable(props: AccountManagementTableProps) {
  const dispatch = useAppDispatch();
  const newUserMode = useAccountManagementNewUserMode();
  const editMode = useAccountManagementEditMode();
  const actionsData = useActionsData();

  const showBirthdayBonus = actionsData.some((action) => action.subtype === "BirthdayBonus");
  const showAnniversaryBonus = actionsData.some((action) => action.subtype === "AnniversaryBonus");

  useEffect(() => {
    dispatch(fetchActions());
  }, [dispatch]);

  return (
    <MainContentWrapper>
      <TableWrapper
        columnsTemplate="1.25fr 1.25fr 1.17fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        minWidth="1330px"
      >
        <AccountManagementTableHeader
          paginatedData={props.data}
          showBirthdayBonus={showBirthdayBonus}
          showAnniversaryBonus={showAnniversaryBonus}
        />

        <Box sx={{ display: "contents" }}>
          {newUserMode && <AccountManagementCreateEditUser groupList={props.groupList} />}
          {props.data.items.map((item) => (<AccountManagementRow
            key={item.id}
            userData={item}
            groupList={props.groupList}
            editMode={editMode}
            showBirthdayBonus={showBirthdayBonus}
            showAnniversaryBonus={showAnniversaryBonus}
          />))}
        </Box>
      </TableWrapper>

      <ServerSidePagination paginatedData={props.data} />
    </MainContentWrapper>
  );
}

export default AccountManagementTable;
