import {useTranslation} from "react-i18next"
import {Box, Typography} from '@mui/material'
import {useUpcomingAnniversariesData} from '../../../store/selectors/reportingSelectors'
import {DashboardSimpleWidgetDataWrapper} from '../Dashboard.styles'
import {fetchUpcomingAnniversariesThunk} from "../../../store/thunk/reportingThunk"
import {useEffect} from "react"
import {useAppDispatch} from "../../../hooks/StoreHooks"

export const UpcomingAnniversariesChart = () => {
  const {t} = useTranslation();
  const upcomingAnniversariesData = useUpcomingAnniversariesData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUpcomingAnniversariesThunk());
  }, [dispatch]);

  return (
    <div>
      <DashboardSimpleWidgetDataWrapper>
        {upcomingAnniversariesData
          ? <>
            <Typography>{t("dashboardPage.upcomingAnniversaries.numberAnniversariesInCurrentMonth", {number: upcomingAnniversariesData.numberAnniversariesInCurrentMonth})}</Typography>
            <Typography>{t("dashboardPage.upcomingAnniversaries.numberAnniversariesInNextMonth", {number: upcomingAnniversariesData.numberAnniversariesInNextMonth})}</Typography>
          </>
          : <Typography>{t("dashboardPage.noDataAvailable")}</Typography>
        }
      </DashboardSimpleWidgetDataWrapper>
    </div>
  )
}
