export const daysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const dayNumbers = [
  {
    number: 1,
    strNumber: "first",
  },
  {
    number: 2,
    strNumber: "second",
  },
  {
    number: 3,
    strNumber: "third",
  },
  {
    number: 4,
    strNumber: "fourth",
  },
];

export const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const yearsList = [
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];

export const colorsData = [
  "#404040",
  "#af924c",
  "#aaacad",
  "#4c4b4c",
  "#000000",
  "#6aa72c",
  "#00873c",
  "#5fcdb8",
  "#009b97",
  "#0088c3",
  "#942593",
  "#fc6c00",
  "#c91100",
  "#c97177",
];

export const periodList = [
  "Daily", 
  "Weekly", 
  "Monthly", 
  "Yearly"
];

export const chartColorList = [
  "#00E6f5",
  "#0094A4",
  "#01657D",
  "#82E9FC",
  "#01A0C4",
  "#00606b",
  "#00C5DA",
  "#003E45",
]

export const MAX_ACTIONS_POINTS = 500;
