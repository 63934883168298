import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
  createNewAccountManagementUserThunk,
  deleteAccountManagementUserThunk,
  editAccountManagementUsersThunk,
} from "../thunk/accountManagementThunk";
import {
  SortingDataType,
  TableSortDirection,
  sortArrayBy,
} from "../../common/logic/TableSortUtils";
import { EmployeeGeneral } from "../../types/employeeType";
import { PaginationType } from "../../types/common";
import { AccountManagementUserType } from "../../types/accountManagementUsersType";

interface AccountManagementInitialState {
  editMode: boolean;
  newUserMode: boolean;
  users: EmployeeGeneral[] | [];
  usersToEdit: EmployeeGeneral[] | [];
  pagination: PaginationType;
  excelUploadEmployeesParsedData: any | null;
}

const initialState: AccountManagementInitialState = {
  editMode: false,
  newUserMode: false,
  users: [],
  usersToEdit: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 1,
  },
  excelUploadEmployeesParsedData: [],
};

const accountManagementReducer = createSlice({
  name: "accountManagement",
  initialState,
  reducers: {
    setAccountManagementEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    sortAccountManagementUsersTable: (
      state,
      action: PayloadAction<{
        dataField: string;
        direction: TableSortDirection;
        dataType: SortingDataType;
      }>
    ) => {
      const { dataField, dataType, direction } = action.payload;
      const arrayToSort = current(state.users);
      state.users = sortArrayBy(arrayToSort, dataField, dataType, direction);
    },
    setNewUserMode: (state, action: PayloadAction<boolean>) => {
      state.newUserMode = action.payload;
    },
    setAccountManagementUsersToEdit: (
      state,
      action: PayloadAction<AccountManagementUserType>
    ) => {
      state.usersToEdit = state.usersToEdit.length === 0
        ? [action.payload]
        : [
          ...state.usersToEdit.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ];
    },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setExcelUploadEmployeesParsedData: (state, action: PayloadAction<Partial<any[]> | []>) => {
      state.excelUploadEmployeesParsedData = { ...state.excelUploadEmployeesParsedData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createNewAccountManagementUserThunk.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.users = action.payload;
          state.newUserMode = false;
        }
      }
    );
    builder.addCase(
      deleteAccountManagementUserThunk.fulfilled,
      (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
      }
    );
    builder.addCase(
      editAccountManagementUsersThunk.fulfilled,
      (state, action) => {
        state.users = state.users.map((user) => {
          const editedUser = action.payload.find((item) => item.id === user.id);
          if (editedUser) return editedUser;
          else return user;
        });
        state.usersToEdit = [];
      }
    );
  },
});

export const {
  setAccountManagementEditMode,
  sortAccountManagementUsersTable,
  setNewUserMode,
  setAccountManagementUsersToEdit,
  setPagination,
  setExcelUploadEmployeesParsedData,
} = accountManagementReducer.actions;

export default accountManagementReducer.reducer;
