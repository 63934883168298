export enum SidebarLinkTypes {
  dashboard = "dashboardPage.title",
  company = "companyPage.title",
  companyManagement = "companyManagementPage.title",
  accountManagement = "accountManagementPage.title",
  groups = "groupsPage.groups.title",
  groupsTaxfreebenefit = "groupsPage.groups.taxFreeBenefit",
  actionModules = "actionModulesPage.actionModules.title",
  actionModulesTaxfreebenefit = "actionModulesPage.actionModules.taxFreeBenefit",
  incentivation = "incentivationPage.title",
  incentivationTaxfreebenefit = "incentivationPage.taxFreeBenefit",
  efficientDriving = "incentivationPage.efficientDriving",
  officeIncentive = "incentivationPage.officeIncentive",
  salesLeads = "incentivationPage.salesLeads",
  presenceQuota = "incentivationPage.presenceQuota",
  signatureControl = "incentivationPage.signatureControl",
  pictureUpload = "incentivationPage.pictureUpload",
  monthlyTaxFreeIncentive = "incentivationPage.monthlyTaxFreeIncentive",
  checklistAnswer = "incentivationPage.checklistAnswer",
  billing = "billingPage.title",
  documentManagement = "documentManagementPage.documentManagement.title",
  documentManagementCertificatesTrainings = "documentManagementPage.documentManagement.certificatesTrainings.title",
  superAdmin = "superAdminPage.title",
  superAdminBillingAdministration = "superAdminPage.billing.billingAdministration",
  configuration = "configurationPage.title",
  appDesign = "configurationPage.appDesign"
}

export enum SidebarLinkPathTypes {
  dashboard = "",
  company = "company",
  companyManagement = "company-management",
  accountManagement = "account-management",
  groups = "groups",
  groupsTaxfreebenefit = "groups/taxfreebenefit",
  actionModules = "actionmodules",
  actionModulesTaxfreebenefit = "actionmodules/taxfreebenefit",
  incentivationTaxfreebenefit = "incentivation/taxfreebenefit",
  efficientDriving = "incentivation/taxfreebenefit/efficientDriving",
  officeIncentive = "incentivation/taxfreebenefit/officeIncentive",
  salesLeads = "incentivation/taxfreebenefit/salesLeads",
  presenceQuota = "incentivation/taxfreebenefit/presenceQuota",
  signatureControl = "incentivation/taxfreebenefit/signatureControl",
  pictureUpload = "incentivation/taxfreebenefit/pictureUpload",
  monthlyTaxFreeIncentive = "incentivation/taxfreebenefit/monthlyTaxFreeIncentive",
  checklistAnswer = "incentivation/taxfreebenefit/checklistAnswer",
  billing = "billing",
  documentManagement = "documentManagement",
  documentManagementCertificatesTrainings = "documentManagement/certificatesTrainings",
  superAdmin = "superadminarea",
  superAdminBillingAdministration = "superadminarea/billingadministration",
  appDesign="configuration/app-design"
}
