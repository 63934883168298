import { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import AccountManagementCreateEditUser from "./AccountManagementCreateEditUser";
import BorderRow from "../../../components/common/table/BorderRow";
import { BasicTableRowStyles } from "../../../components/common/Common.styles";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { AccountManagementUserType } from "../../../types/accountManagementUsersType";
import TooltipTypography from "../../../components/common/TooltipTypography";
import { Download } from "@mui/icons-material";
import { InvitationCodeStyles } from "../AccountManagement.styles";
import { Link } from "react-router-dom";
import { GroupItem } from "../../../types/groupItem";

interface AccountManagementRowProps {
  userData: AccountManagementUserType;
  groupList: GroupItem[];
  editMode: boolean;
  showBirthdayBonus: boolean;
  showAnniversaryBonus: boolean;
}

const AccountManagementRow: FC<AccountManagementRowProps> = ({
  userData,
  groupList,
  editMode,
  showBirthdayBonus,
  showAnniversaryBonus,
}) => {
  if (editMode) {
    return <AccountManagementCreateEditUser
      user={userData}
      groupList={groupList} />;
  }

  const dayjs = require("dayjs");
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  return (
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={userData.firstName}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={userData.lastName}
        />
      </CellBox>
      {showBirthdayBonus && (
        <CellBox>
          <TooltipTypography
            value={userData.birthDate
              ? dayjs(userData.birthDate).format("DD.MM.YYYY")
              : ""}
          />
        </CellBox>
      )}
      {showAnniversaryBonus && (
        <CellBox>
          <TooltipTypography
            value={userData.employmentDate
              ? dayjs(userData.employmentDate).format("DD.MM.YYYY")
              : ""}
          />
        </CellBox>
      )}
      <CellBox>
        <TooltipTypography
          value={userData.position}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={userData.employeeNumber}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={userData.groupName ? userData.groupName : "-"}
        />
      </CellBox>
      <CellBox>
        <StatusDisplay status={userData.status} />
      </CellBox>
      <CellBox>
        <InvitationCodeStyles>
          <Typography variant="caption" fontSize={16}>{userData.registrationCode}</Typography>
          {userData.onboardingFileDownloadUrl && <Link to={userData.onboardingFileDownloadUrl} target="_blank">
            <IconButton>
              <Download />
            </IconButton>
          </Link>}
        </InvitationCodeStyles>
      </CellBox>
      <CellBox>
        <Box></Box>
      </CellBox>
      <BorderRow />
    </BasicTableRowStyles>
  );
};

export default AccountManagementRow;
