import {useTranslation} from "react-i18next"
import {Typography} from '@mui/material'
import {useUpcomingBirthdaysData} from '../../../store/selectors/reportingSelectors'
import {DashboardSimpleWidgetDataWrapper} from '../Dashboard.styles'
import {useEffect} from "react"
import {fetchUpcomingBirthdaysThunk} from "../../../store/thunk/reportingThunk"
import {useAppDispatch} from "../../../hooks/StoreHooks"

export const UpcomingBirthdaysChart = () => {
  const {t} = useTranslation();
  const upcomingBirthdaysData = useUpcomingBirthdaysData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUpcomingBirthdaysThunk());
  }, [dispatch]);

  return (
    <div>
      <DashboardSimpleWidgetDataWrapper>
        {upcomingBirthdaysData
          ? <>
            <Typography>{t("dashboardPage.upcomingBirthdays.numberBirthdaysInCurrentMonth", {number: upcomingBirthdaysData.numberBirthdaysInCurrentMonth})}</Typography>
            <Typography>{t("dashboardPage.upcomingBirthdays.numberBirthdaysInNextMonth", {number: upcomingBirthdaysData.numberBirthdaysInNextMonth})}</Typography>
          </>
          : <Typography>{t("dashboardPage.noDataAvailable")}</Typography>
        }
      </DashboardSimpleWidgetDataWrapper>
    </div>
  )
}
