import { Box, styled } from "@mui/material";

export const ScoreRangesWrapper = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxWidth: "100%",
    overflow: "hidden",
});

export const ScoreRangesTable = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    borderRadius: "8px",
}));

export const ScoreRangesRow = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "8px 16px",
    "&:not(:last-child)": {
        borderBottom: "1px solid #e0e0e0"
    },
    "&:hover": {
        backgroundColor: "#f5f5f5",
    },
});

export const ScoreRangeMissingBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: "16px",
    borderRadius: "8px",
    textAlign: "center",
    marginTop: "16px",
    "& > .MuiTypography-root": {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: 500,
    },
}));
