import {useTranslation} from "react-i18next"
import {Box, Typography} from '@mui/material'
import {useChecklistAnswerStreakData} from '../../../store/selectors/reportingSelectors'
import {DashboardSimpleWidgetDataWrapper} from '../Dashboard.styles'
import {useEffect} from "react"
import {fetchChecklistAnswerStreaksThunk} from "../../../store/thunk/reportingThunk"
import {useAppDispatch} from "../../../hooks/StoreHooks"

export const ChecklistAnswerStreakChart = () => {
  const {t} = useTranslation();
  const checklistAnswerStreakData = useChecklistAnswerStreakData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchChecklistAnswerStreaksThunk());
  }, [dispatch]);

  return (
    <div>
      <DashboardSimpleWidgetDataWrapper>
        {checklistAnswerStreakData
          ? checklistAnswerStreakData.map((streak) =>
            <Typography>
              {t("dashboardPage.checklistAnswerStreak.message", {
                streak: streak.streak,
                actionModuleName: streak.actionModuleName
              })}
            </Typography>)
          : <Box>{t("dashboardPage.noDataAvailable")}</Box>
        }
      </DashboardSimpleWidgetDataWrapper>
    </div>
  )
}

