import {
  DashboardContentStyles,
  DashboardGrid,
  StatisticsWrapper,
} from "./Dashboard.styles";
import AveragePointsChart from "./widgets/AveragePointsChart";
import TotalPointsChart from "./widgets/TotalPointsChart";
import ActionModulesDoneChart from "./widgets/ActionModulesDoneChart";
import PresenceQuotaChart from "./widgets/PresenceQuotaChart";
import ControlSignatureChart from "./widgets/ControlSignatureChart";
import GeneralInfoData from "./widgets/GeneralInfoData";
import { EvaluationStarsChart } from "./widgets/EvaluationStarsChart";
import ExpandableWidget from "./widgets/ExpandableWidget";
import { useActionsData, useSubtypeList } from "../../store/selectors/actionsSelectors";
import { CoinsIcon, FamilyStarIcon, SignatureIcon } from "../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { PersonIcon, ThumbUpIcon } from "../../assets/icons/ActionIcons";
import { UpcomingBirthdaysChart } from "./widgets/UpcomingBirthdaysChart";
import { UpcomingAnniversariesChart } from "./widgets/UpcomingAnniversariesChart";
import { theme } from "../../theme";
import { ChecklistAnswerStreakChart } from "./widgets/ChecklistAnswerStreakChart";
import { EvaluationThumbsChart } from "./widgets/EvaluationThumbsChart";

const DashboardContent = () => {
  const { t } = useTranslation();
  const subtypeList = useSubtypeList()
  const actionsData = useActionsData();
  console.log("actinsdata")
  console.log(actionsData)

  const widgetConfig = [
    {
      title: t("dashboardPage.averagePoints.title"),
      icon: CoinsIcon,
      component: <AveragePointsChart />,
      condition: true,
    },
    {
      title: t("dashboardPage.totalPoints.title"),
      icon: CoinsIcon,
      component: <TotalPointsChart />,
      condition: true,
    },
    {
      title: t("dashboardPage.actionModulesDone.title"),
      icon: CoinsIcon,
      component: <ActionModulesDoneChart />,
      condition: true,
    },
    {
      title: t("dashboardPage.presenceQuota.title"),
      icon: CoinsIcon,
      component: <PresenceQuotaChart />,
      condition: subtypeList.subTypeNames.includes("PresenceQuota"),
    },
    {
      title: t("dashboardPage.evaluationStars.title"),
      icon: FamilyStarIcon,
      component: <EvaluationStarsChart />,
      condition: subtypeList.subTypeNames.includes("EvaluationStars")
    },
    ...actionsData
      .filter((action) => action.subtype === "EvaluationThumbs")
      .map((action, index) => ({
        title: `${t("dashboardPage.evaluationThumbs.title")} (${action.name})`,
        icon: ThumbUpIcon,
        component: <EvaluationThumbsChart actionModuleId={action.id}/>,
        condition: true, 
      })),
    {
      title: t("dashboardPage.controlSignature.title"),
      icon: SignatureIcon,
      component: <ControlSignatureChart />,
      condition: subtypeList.subTypeNames.includes("SignatureControl"),
    },
    {
      title: t("dashboardPage.upcomingBirthdays.title"),
      icon: (props) => (
        <PersonIcon {...props} colorHex={theme.palette.companyMain.main} />
      ),
      component: <UpcomingBirthdaysChart />,
      condition: subtypeList.subTypeNames.includes("BirthdayBonus"),
    },
    {
      title: t("dashboardPage.upcomingAnniversaries.title"),
      icon: (props) => (
        <PersonIcon {...props} colorHex={theme.palette.companyMain.main} />
      ),
      component: <UpcomingAnniversariesChart />,
      condition: subtypeList.subTypeNames.includes("AnniversaryBonus"),
    },
    {
      title: t("dashboardPage.checklistAnswerStreak.title"),
      icon: (props) => (
        <PersonIcon {...props} colorHex={theme.palette.companyMain.main} />
      ),
      component: <ChecklistAnswerStreakChart />,
      condition: subtypeList.subTypeNames.includes("ChecklistAnswer"),
    },
  ];

  return (
    <DashboardContentStyles>
      <DashboardGrid>
        <StatisticsWrapper>
          <GeneralInfoData />
        </StatisticsWrapper>
        {widgetConfig.map(
          (widget, index) =>
            widget.condition && (
              <StatisticsWrapper key={index}>
                <ExpandableWidget
                  widgetTitle={widget.title}
                  IconComponent={widget.icon}
                >
                  {widget.component}
                </ExpandableWidget>
              </StatisticsWrapper>
            )
        )}
      </DashboardGrid>
    </DashboardContentStyles>
  );
};

export default DashboardContent;
