import { Box, Button, Popover } from "@mui/material";
import {FC, MutableRefObject, useState} from "react";
import { useSearch } from "../../../../../../hooks/UseSearch";
import SimpleSearch from "../../../../../../components/common/SimpleSearch";
import ActionsListItem from "./ActionsListItem";
import {useActionsData, useBenefitList} from "../../../../../../store/selectors/actionsSelectors";
import {
  ActionsPopoverStyles,
  ApplyBtnWrapper,
} from "../../../GroupsBlock.styles";
import { useTranslation } from "react-i18next";
import {ActionItem} from "../../../../../../types/actionItem";
import {ModalComponent} from "../../../../../../components/common/ModalComponent";

interface ActionsPopoverProps {
  anchorRef: MutableRefObject<null>;
  actions?: any[];
  open: boolean;
  selectedActions: any[];
  handleClose: () => void;
  handleOnEdit: () => void;
  setSelectedActions: (newSelectedActions: ActionItem[]) => void;
}

const ActionsPopover: FC<ActionsPopoverProps> = ({
  handleClose,
  handleOnEdit,
  anchorRef,
  open,
  selectedActions,
  setSelectedActions,
}) => {
  const { searchQuery, handleOnSearch } = useSearch();

  const [errorMessage, setErrorMessage] = useState<String | null>(null)

  const { t } = useTranslation();
  const actionsStateData = useActionsData();
  const benefitList = useBenefitList()

  const actionsData = searchQuery
    ? actionsStateData.filter((item) => item.name.toLowerCase().includes(searchQuery))
    : actionsStateData;

  function onUpdateSelectedActions(newSelection: ActionItem[]) {
    const hasCompanyOnlyTaxFreeBenefits = benefitList.every((b) => b.benefitType === "TaxFreeBenefit")

    if (hasCompanyOnlyTaxFreeBenefits) {
      let totalPoints = 0
      newSelection.forEach((elem) => {
        totalPoints += elem.maxPoints
      })

      if (totalPoints > 500) {
        setErrorMessage(t("dialogModals.groupMaxPointsOver500"));
        return;
      } else if (totalPoints < 500) {
        setErrorMessage(t("dialogModals.groupMaxPointsUnder500", {
          maxAmount: totalPoints / 10
        }));
      }
    }

    setSelectedActions(newSelection);
  }

  const actionItems = actionsData.map((item) => {
    const isSelected = !!selectedActions.find(
      (action) => action.id === item.id
    );

    return (
      <ActionsListItem
        key={item.id}
        item={item}
        isActionSelected={isSelected}
        onUpdateSelectedActions={onUpdateSelectedActions}
        selectedActions={selectedActions}
      />
    );
  });

  return (
    <Popover
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      anchorEl={anchorRef.current}
      open={open}
    >
      <ActionsPopoverStyles>
        <SimpleSearch
          onSearchHandler={handleOnSearch}
          searchQuery={searchQuery}
        />
        <Box>{actionItems}</Box>
      </ActionsPopoverStyles>
      <ApplyBtnWrapper>
        <Button
          onClick={() => {
            handleClose();
            handleOnEdit();
          }}
        >
          {t("buttons.apply")}
        </Button>
      </ApplyBtnWrapper>

      <ModalComponent isOpen={errorMessage != null} onClose={() => setErrorMessage(null)}>
        <Box>{errorMessage}</Box>
      </ModalComponent>
    </Popover>
  );
};

export default ActionsPopover;
