import {Box, Button, Collapse, IconButton, Typography} from "@mui/material";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import BorderRow from "../../components/common/table/BorderRow";
import CellBox from "../../components/common/table/CellBox";
import StatusDisplay from "../../components/common/table/StatusDisplay";
import { ServerSidePagination } from "../../components/common/table/ServerSidePagination";
import EmployeeIncentivationType from "../../types/employeeIncentivationType";
import { ActionItem, FulfilledStatusType } from "../../types/actionItem";
import FulfilledStatusIcon, { FulfilledIconWrapper } from "../../components/common/table/FulfilledStatusIcon";
import ServerSideTableColumnHeader from "../../components/common/table/ServerSideTableColumnHeader";
import { NumberInput } from "../../components/common/table/NumberInput";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from "react";
import ExpandedTable from "./overview/ExpandedTable";
import { useActionEditMode } from "../../store/selectors/actionsSelectors";
import {PaginatedData} from "../../hooks/PaginationHooks";

interface Props {
    paginatedData: PaginatedData<EmployeeIncentivationType>
    actionModule: ActionItem;
    onRowUpdated: (newValue: EmployeeIncentivationType) => void;
}

export default function IncentivationTable(props: Props) {
    const pictureUploadModule = props.actionModule.subtype === "PictureUpload"
    const checklistAnswerModule = props.actionModule.subtype === "ChecklistAnswer"

    const showSuccessfulUploads = pictureUploadModule
    const showAnswerDates = checklistAnswerModule
    const showScore = props.actionModule.type.includes("ExcelIncentive") || pictureUploadModule
    const showFulfillment = props.actionModule.type.includes("Manual") && !showAnswerDates;
    const showPartiallyFulfilled = showFulfillment && props.actionModule.rewardedPointsPartiallyFullfilled > 0;

    const fulfillmentTableTemplate = `1fr ${showPartiallyFulfilled ? `1fr` : ""} 1fr`;
    const tableTemplate = `1fr 3fr 2fr 2fr 1fr ${showSuccessfulUploads ? "1fr" : ""} ${showFulfillment ? fulfillmentTableTemplate : ""} ${showScore ? "1fr" : ""} ${showAnswerDates ? "1fr 1fr 1fr" : ""} 1fr 1fr`;

    const isExpandable = pictureUploadModule || checklistAnswerModule

    return <Box>
        <IncentivationTableHeader
            showFulfillment={showFulfillment}
            showPartiallyState={showPartiallyFulfilled}
            showScore={showScore}
            showAnswerDates={showAnswerDates}
            positiveAnswerText={props.actionModule.positiveAnswerText}
            negativeAnswerText={props.actionModule.negativeAnswerText}
            paginatedData={props.paginatedData}
            expandable={pictureUploadModule}
            showSuccessfulUploads={showSuccessfulUploads}
            tableTemplate={tableTemplate} />
        <Box sx={{ display: "contents" }}>
            {
                props.paginatedData.items.map((u) => <IncentivationTableRow
                    key={u.id}
                    row={u}
                    actionItem={props.actionModule}
                    showFulfillment={showFulfillment}
                    showPartiallyState={showPartiallyFulfilled}
                    showScore={showScore}
                    showAnswer={showAnswerDates}
                    onRowUpdated={props.onRowUpdated}
                    showSuccessfulUploads={showSuccessfulUploads}
                    expandable={isExpandable}
                    tableTemplate={tableTemplate} />)
            }
        </Box>
        <ServerSidePagination paginatedData={props.paginatedData}/>
    </Box>
}

interface IncentivationTableHeaderProps {
    showFulfillment: boolean;
    showPartiallyState: boolean;
    showScore: boolean;
    showAnswerDates: boolean;
    showSuccessfulUploads?: boolean;
    positiveAnswerText?: string;
    negativeAnswerText?: string;
    paginatedData: PaginatedData<any>;
    expandable?: boolean;
    tableTemplate: string;
}

function IncentivationTableHeader(props: IncentivationTableHeaderProps) {
    const { t } = useTranslation();

    return <Box gridTemplateColumns={props.tableTemplate} display="grid">
        <TableHeaderWrapper>
            <ServerSideTableColumnHeader
                fieldName="Exp"
                label=""
                firstCol={true}
            />
            <ServerSideTableColumnHeader
                fieldName="Name"
                isSorted={true}
                label={t("tableColumnHeaders.name")}
                paginatedData={props.paginatedData}
            />
            <ServerSideTableColumnHeader
                fieldName="Position"
                isSorted={true}
                label={t("tableColumnHeaders.position")}
                paginatedData={props.paginatedData}
            />
            <ServerSideTableColumnHeader
                fieldName="EmployeeNumber"
                isSorted={true}
                label={t("tableColumnHeaders.employeeNo")}
                paginatedData={props.paginatedData}
            />
            {
              !props.expandable &&
              <ServerSideTableColumnHeader
                isSorted={false}
                label={t("tableColumnHeaders.dateOfAction")}
              />
            }
            {props.showSuccessfulUploads &&
                <ServerSideTableColumnHeader
                    fieldName="successfulUploads"
                    label={t("tableColumnHeaders.successfulUploads")}
                />
            }
            {props.showFulfillment && <>
                <ServerSideTableColumnHeader
                    label={t("tableColumnHeaders.notFulfilled")}
                    prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.NotFulfilled} />}
                />
                {props.showPartiallyState && (
                    <ServerSideTableColumnHeader
                        label={t("tableColumnHeaders.partiallyFulfilled")}
                        prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.Partially} />}
                    />
                )}
                <ServerSideTableColumnHeader
                    label={t("tableColumnHeaders.fulfilled")}
                    prevIcon={<FulfilledStatusIcon sx={{ marginRight: "2px" }} fulfilledStatus={FulfilledStatusType.Fulfilled} />}
                />
            </>}
            {props.showScore && <ServerSideTableColumnHeader
                fieldName="score"
                label={t("tableColumnHeaders.scoreValue")}
            />}
            {props.showAnswerDates &&
              <>
                  <ServerSideTableColumnHeader
                    fieldName="positive"
                    label={props.positiveAnswerText ?? ""}
                  />
                  <ServerSideTableColumnHeader
                    fieldName="negative"
                    label={props.negativeAnswerText ?? ""}
                  />
                  <ServerSideTableColumnHeader
                    fieldName="negative"
                    label={t("tableColumnHeaders.notAnswered")}
                  />
              </>
            }
            <ServerSideTableColumnHeader
                fieldName="points"
                label={t("tableColumnHeaders.points")}
            />
            <ServerSideTableColumnHeader
                fieldName="status"
                label={t("tableColumnHeaders.status")}
                lastCol={true}
            />
            <BorderRow />
        </TableHeaderWrapper>
    </Box>
}

interface IncentivationOverviewTableRowProps {
    row: EmployeeIncentivationType;
    actionItem: ActionItem;
    showFulfillment: boolean;
    showPartiallyState: boolean;
    showScore: boolean;
    showAnswer: boolean;
    showSuccessfulUploads: boolean;
    onRowUpdated: (newRow: EmployeeIncentivationType) => void;
    expandable: boolean;
    tableTemplate: string;
}

function IncentivationTableRow(props: IncentivationOverviewTableRowProps) {
    const [open, setOpen] = useState(false);
    const actionEditMode = useActionEditMode();
    function handleFulfillmentStatusChange(newState: FulfilledStatusType) {
        props.onRowUpdated({
            ...props.row,
            fulfillmentStatus: newState,
            score: undefined,
        })
    }

    return (
        <>
            <Box gridTemplateColumns={props.tableTemplate} display="grid">
                <BasicTableRowStyles>
                    {props.expandable
                        ?
                        <CellBox firstCol={true}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </CellBox>
                        :
                        <CellBox>
                            <Typography></Typography>
                        </CellBox>
                    }
                    <CellBox firstCol={true}>
                        <Typography>{props.row.name}</Typography>
                    </CellBox>
                    <CellBox>
                        <Typography>{props.row.position}</Typography>
                    </CellBox>
                    <CellBox>
                        <Typography>{props.row.employeeNumber}</Typography>
                    </CellBox>
                    {
                      !props.expandable &&
                      <CellBox>
                          <Typography>{props.row.dateOfAction
                            ? new Date(props.row.dateOfAction).toLocaleDateString()
                            : ""}</Typography>
                      </CellBox>
                    }
                    {props.showSuccessfulUploads &&
                        <CellBox>
                            <Typography>{props.row.successfulUploadedInstances}/{props.row.totalInstances}</Typography>
                        </CellBox>
                    }
                    {props.showFulfillment && <>
                        <CellBox>
                            <Button
                                disabled={!actionEditMode}
                                onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.NotFulfilled)}>
                                {props.row.fulfillmentStatus === FulfilledStatusType.NotFulfilled
                                    ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.NotFulfilled} />
                                    : <FulfilledIconWrapper />
                                }
                            </Button>
                        </CellBox>
                        {
                            props.showPartiallyState &&
                            <CellBox>
                                <Button
                                    disabled={!actionEditMode}
                                    onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Partially)}>
                                    {props.row.fulfillmentStatus === FulfilledStatusType.Partially
                                        ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Partially} />
                                        : <FulfilledIconWrapper />
                                    }
                                </Button>
                            </CellBox>
                        }
                        <CellBox>
                            <Button
                                disabled={!actionEditMode}
                                onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Fulfilled)}>
                                {props.row.fulfillmentStatus === FulfilledStatusType.Fulfilled
                                    ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Fulfilled} />
                                    : <FulfilledIconWrapper />
                                }
                            </Button>
                        </CellBox>
                    </>
                    }
                    {props.showScore &&
                        <CellBox contentPosition="center">
                            {
                                !actionEditMode
                                    ? <Typography>{props.row.score === 0 || !props.row.score || isNaN(props.row.score) ? "N/A" : props.row.score}</Typography>
                                    : <NumberInput
                                        aria-label="Input"
                                        placeholder={"0"}
                                        value={props.row.score}
                                        onChange={(e, val) => props.onRowUpdated({
                                            ...props.row,
                                            score: val ?? undefined
                                        })}
                                    />
                            }
                        </CellBox>
                    }
                    {props.showAnswer && <>
                        <CellBox>
                            <Typography>{props.row.positiveAnswerCount}</Typography>
                        </CellBox>
                        <CellBox>
                            <Typography>{props.row.negativeAnswerCount}</Typography>
                        </CellBox>
                        <CellBox>
                            <Typography>{props.row.notAnsweredCount}</Typography>
                        </CellBox>
                      </>
                    }
                    <CellBox>
                        <Typography>{props.row.points}</Typography>
                    </CellBox>
                    <CellBox lastCol={true} contentPosition="center">
                        <StatusDisplay status={props.row.status} />
                    </CellBox>
                </BasicTableRowStyles>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <ExpandedTable
                  rowData={props.row}
                  actionItem={props.actionItem}
                  showFulfillment={props.showFulfillment}
                  showPartiallyState={props.showPartiallyState}
                  showAnswer={props.showAnswer}
                  showScore={props.showScore}
                  showSuccessfulUploads={props.showSuccessfulUploads}
                />
            </Collapse>
        </>
    );
}